<template lang="pug">
v-card(light, flat)
    v-container(light)
        v-row
            v-col.d-flex
                v-btn(text, @click='goBack')
                    v-icon(left) arrow_left
                    | Communications
        v-row
            v-col
                span.text-h5 Edit Bulletin
        v-row
            v-col
                v-text-field(label='Name', outlined, dense, color='brand', v-model='bulletinName', hide-details)
            v-col
                v-text-field(label='Description', outlined, dense, color='brand', v-model='description', hide-details)
        v-row
            v-col
                v-text-field(label='Title', outlined, dense, color='brand', v-model='bulletinTitle', hide-details)
        v-row
            v-col
                v-menu(ref='dateMenu', v-model='dateMenu', :close-on-content-click='false', :return-value.sync='publishDate', transition='scale-transition', offset-y, min-width='auto')
                    template(v-slot:activator='{on, attrs}')
                        v-text-field(label='Publish Date', outlined, dense, color='brand', v-model='publishDate', readonly, v-bind='attrs', v-on='on')
                    v-date-picker(v-model='publishDate', no-title, scrollable, light)
                        v-spacer
                        v-btn(text, @click='dateMenu=false') Cancel
                        v-btn.white--text(color='brand', @click='$refs.dateMenu.save(publishDate)') Ok
            v-col
                v-text-field(v-model='bulletinType', disabled, outlined, dense, color='brand', label='Type')
        v-row
            v-col
                VueTrix(v-model='bulletinBody')
        v-row
            v-col.d-flex
                v-spacer
                v-btn.white--text.mr-2(color='red', @click='confirmDelete', :loading='deleting') Delete
                v-btn.white--text(color='brand', @click='saveBulletin', :loading='saving') Save

    v-dialog(v-model='bulletinDeleteDialog', max-width='300')
        v-card(light)
            v-card-title Delete Bulletin
            v-card-text Are you sure you want to delete this bulletin?
            v-card-actions
                v-spacer
                v-btn(text, @click='bulletinDeleteDialog=false') Cancel
                v-btn.white--text(color='red', @click='deleteBulletin') Delete

    v-snackbar(v-model='showAlert', dark, :timeout='3000') {{ snackbarMessage }}
</template>
<script>
import VueTrix from 'vue-trix';
export default {
    components: {
        VueTrix
    },
    data () {
        return {
            accessKey: 'devopscomms',
            bulletinBody: '',
            bulletinTitle: '',
            description: '',
            bulletinType: '',
            bulletinName: '',
            saving: false,
            snackbarMessage: '',
            bulletinDeleteDialog: false,
            deleting: false,
            publishDate: '',
            dateMenu: false
        };
    },
    computed: {
        /**
         * ID of bulletin to edit
         */
        bulletinId () {
            return this.$route.query.id;
        },
        showAlert: {
            get () {
                return this.snackbarMessage !== '';
            },
            set (val) {
                if (val === false) {
                    this.snackbarMessage = '';
                }
            },
        }
    },
    methods: {
        /**
         * Fetches bulletin data from server.
         */
        async getBulletin () {
            const bulletin = await this.sendCommand({
                action: 'getBulletin',
                parameters: this.bulletinId
            });
            this.bulletinBody = bulletin.content;
            this.bulletinTitle = bulletin.title;
            this.publishDate = bulletin.publishDate;
            this.description = bulletin.description;
            this.bulletinType = bulletin.type;
            this.bulletinName = bulletin.name;
        },
        /**
         * Navigates to main communications page.
         */
        goBack () {
            this.$router.push('/devops/communications');
        },
        /**
         * Saves the current bulletin
         */
        async saveBulletin () {
            this.saving = true;
            const result = await this.sendCommand({
                action: 'saveBulletin',
                parameters: {
                    content: this.bulletinBody,
                    title: this.bulletinTitle,
                    publishDate: this.publishDate,
                    description: this.description,
                    name: this.bulletinName,
                    id: this.bulletinId
                }
            });
            if (result) {
                if (result.err) {
                    this.snackbarMessage = result.err;
                } else {
                    this.snackbarMessage = 'Saved succesfully!';
                }
            } else {
                this.snackbarMessage = 'An unknown error occured!';
            }
            this.saving = false;
        },
        /**
         * Brings up the delete confirmation dialog.
         */
        confirmDelete () {
            this.bulletinDeleteDialog = true;
        },
        /**
         * Deletes the current bulletin.
         */
        deleteBulletin () {
            this.deleting = true;
            this.$nextTick(async () => {
                const result = await this.sendCommand({
                    action: 'deleteBulletin',
                    parameters: {
                        id: this.bulletinId
                    }
                });
                if (result) {
                    if (result.err) {
                        this.snackbarMessage = result.err;
                    } else {
                        this.snackbarMessage = 'Deleted bulletin!';
                    }
                } else {
                    this.snackbarMessage = 'An unknown error occured!';
                }
                this.deleting = false;
                this.$router.push('/devops/communications');
            });
        }
    },
    watch: {
        bulletinId: {
            immediate: true,
            handler () {
                this.getBulletin();
            }
        }
    }
};
</script>
<style lang='scss'>
trix-editor {
    a {
        color: blue !important;
    }
}
</style>
